import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = ["| 3일부터 스테이지파이브-롯데카드 제휴카드 할인 서비스 시작", "| 일상에서의 실질적 고객 혜택 확대에 가속도"]

const content = () => (
  <div>
    <br />
    <div className="descImage promotion">
      <div>
        <img src="/images/press/11/eaLtcbDw.png" alt="Figure1" />
      </div>
    </div>
    <p>
      <br />
      ㈜스테이지파이브(공동대표 이제욱 ㆍ 서상원, 이하 스테이지파이브)가 운영하는 비대면 통신 가입 플랫폼 ‘핀다이렉트샵’이
      3일(월)부터 롯데카드 제휴 서비스를 시작한다.
      <br />
      <br />
      이번 제휴 서비스는 핀다이렉트샵 알뜰폰 유심 요금제 가입 시 롯데카드 ‘Mobile x LOCA 카드’로 자동 이체 신청을 하면, 최대 월
      2만원의 청구할인을 제공한다. 이에 더하여 가입상태를 13개월 이상 유지할 시, 통신요금 10%할인 및 국내 롯데카드 전 가맹점
      할인을 제공 받을 수 있다.
      <br />
      <br />
      카드 제휴 혜택은 지난달 이용 실적에 따라 적용되며, 지난달 카드 이용금액 40만원 이상 충족 시 매달 1만5천원, 지난달 카드
      이용금액 70만원 이상 충족 시 매달 2만원의 결제일 할인을 받을 수 있게 된다.
      <br />
      <br />
      가령, 핀다이렉트샵의 1만 6천원 요금제(완전자유 1.4GB+)를 가입하는 고객의 경우, Mobile x LOCA 카드를 월 70만원 이상 사용했을
      시 실질적으로 매월 0원의 요금이 청구되는 셈이다.
      <br />
      <br />본 서비스는 별도의 종료 기한 없이 핀다이렉트샵 가입 고객에게 제공될 계획이다. 가입을 원하는 이는 핀플레이 홈페이지(
      <a
        href="https://www.pinplay.co.kr/mvno/usim-fare"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "underline" }}
      >
        https://www.pinplay.co.kr/mvno/usim-fare
      </a>
      )에서 신청할 수 있다.
      <br />
      <br />
      스테이지파이브 서상원 공동대표는 “알뜰폰도 이제는 단순히 싼 가격의 통신 상품 제공에만 머물러서는 경쟁력이 없다”며,
      “스테이지파이브는 데이터와 음성으로 양분되는 획일화된 요금제 판매에만 머무르지 않고, 다양한 서비스를 결합하여 유저의 라이프
      스타일에 맞는 통신 서비스를 제공할 것”이라고 전했다.
      <br />
      <br />
      스테이지파이브는 이번 제휴를 기점으로, 실질적 혜택과 다양한 서비스를 발굴하여 유저의 라이프 스타일에 집중한 서비스 라인업을
      계속해서 늘려 나갈 방침이다
      <br />
      <br />
      {`
        스테이지파이브는 지난달 초저가로 MVNO(알뜰폰) 5G 및 LTE 유심 요금제 4종을 출시한 바 있다. 출시기념 프로모션 할인가로 ▲<5G 슬림 10GB+> 월 27,000원(데이터 무제한 10GB+1Mbps, 전화 및 문자 무제한)▲LTE <완전자유 11GB+> 월 23,330원 (데이터 무제한 11GB+일2GB+3Mbps,통화 및 문자 무제한) ▲LTE <데이터안심 15GB+> 월 21,000원(데이터 무제한 15GB+3Mbps, 음성통화 100분, 문자100건) ▲LTE <완전자유 1.4GB+> 16,000원(데이터 무제한 1.4GB+1Mbps, 음성 및 문자 무제한)을 제공하고 있다.
      `}
      <br />
      <br />
      한편, 스테이지파이브는 신규 요금제 4종 특가에 더해 추가 데이터 제공 프로모션을 진행 중이다. 5G슬림 10GB+, 완전자유 11GB+,
      데이터안심 15GB+ 가입 고객에게는 24개월간 매월 4~100GB의 추가 데이터를 제공한다. 완전자유1.4GB+ 가입 고객에게는 해지 시까지
      추가 데이터가 제공된다. 가입상담은 카카오톡 핀다이렉트샵 톡채널에서 가능하다.
      <br />
      <br />
    </p>
  </div>
)

const press11 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 4월 30일 즉시 배포 가능 (5월 1일 조간)"
      pageInfo="*총 2매/ 첨부: 사진 3매"
      title="스테이지파이브 핀다이렉트샵, 롯데카드 제휴로<br />통신료 월 최대 2만원 할인"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press11
